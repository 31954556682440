// @ts-check
/**
 * A class that handles the Yielding logic & helps generate replacement logic.
 */
class YieldStructure {
  constructor (data) {
    this._logic = null
    this.resumable = null
    this._input = null
    Object.assign(this, data)
  }

  logic () {
    // if (this._input && !this._logic) {
    // return { [this._name]: this._input }
    // }
    return this._logic
  }
}

// @ts-check
const mutateKey = (i) => i
const mutateValue = (i) => i
const skipCopy = (i) => false
const defaultOptions = { mutateKey, mutateValue, skipCopy }
/**
 *
 * @param {Object} obj
 * @param {Object} target
 * @param {{ mutateKey?: Function, mutateValue?: Function, skipCopy?: Function }} options
 * @returns
 */
function traverseCopy (obj, target = {}, options = defaultOptions) {
  const { mutateKey, mutateValue, skipCopy } = {
    ...defaultOptions,
    ...options
  }
  if (typeof obj === 'object' && !Array.isArray(obj) && obj) {
    if (skipCopy(obj)) return mutateValue(obj)
    Object.keys(obj).forEach((key) => {
      target[mutateKey(key)] = mutateValue(traverseCopy(obj[key], {}, options))
    })
    return target
  } else if (Array.isArray(obj)) {
    return [...obj].map((i, x) => mutateValue(traverseCopy(i, {}, options)))
  } else {
    return mutateValue(obj)
  }
}

// @ts-check

function fetchYields (obj, arr = []) {
  if (obj instanceof YieldStructure) {
    arr.push(obj)
    return arr
  }
  if (Array.isArray(obj)) {
    obj.forEach((i) => fetchYields(i, arr))
  } else if (typeof obj === 'object') {
    Object.keys(obj || {}).forEach((key) => {
      fetchYields(obj[key], arr)
    })
  }
  return arr
}
class EngineObject {
  constructor (data) {
    this.data = data
  }

  yields () {
    return fetchYields(this.data.result, [])
  }

  logic () {
    return traverseCopy(
      this.data.result,
      {},
      {
        mutateValue: (i) => {
          if (i instanceof YieldStructure) {
            return i.logic()
          }
          return i
        },
        skipCopy: (i) => i instanceof YieldStructure
      }
    )
  }
}

// @ts-check

/**
 * Checks if the item that's passed in is or contains a YieldStructure / EngineObject (signifying a YieldStructure)
 * @param {*} item
 * @returns {Boolean}
 */
function checkYield (item) {
  if (Array.isArray(item)) {
    return item.some((i) => i instanceof YieldStructure || i instanceof EngineObject)
  }
  return item instanceof YieldStructure || item instanceof EngineObject
}

// @ts-check
// Note: Each of these iterators executes synchronously, and will not "run in parallel"
// I am supporting filter, reduce, some, every, map
async function filter (arr, iter) {
  const result = []
  for (const item of arr) {
    if (await iter(item)) result.push(item)
  }
  return result
}

async function some (arr, iter) {
  for (const item of arr) {
    if (await iter(item)) return true
  }
  return false
}

async function every (arr, iter) {
  for (const item of arr) {
    if (!(await iter(item))) return false
  }
  return true
}

async function map (arr, iter) {
  const result = []
  for (const item of arr) {
    result.push(await iter(item))
  }
  return result
}

async function reduce (arr, iter, defaultValue) {
  if (arr.length === 0) {
    if (typeof defaultValue !== 'undefined') {
      return defaultValue
    }
    throw new Error('Array has no elements.')
  }

  const start = typeof defaultValue === 'undefined' ? 1 : 0
  let data = start ? arr[0] : defaultValue

  for (let i = start; i < arr.length; i++) {
    data = await iter(data, arr[i])
  }

  return data
}

const asyncIterators = {
  filter,
  some,
  every,
  map,
  reduce
}

// @ts-check

const Sync = Symbol.for('json_logic_sync')
const Override = Symbol.for('json_logic_override')
const EfficientTop = Symbol.for('json_logic_efficientTop')
const isSync = (x) => Boolean(typeof x !== 'function' || x[Sync])
const Constants = {
  Sync,
  Override,
  EfficientTop,
  isSync
}

// @ts-check
function declareSync (obj, sync = true) {
  obj[Sync] = sync
  return obj
}

// @ts-check

/**
 * @typedef BuildState
 * Used to keep track of the compilation.
 * @property {*} [engine]
 * @property {Object} [notTraversed]
 * @property {Object} [functions]
 * @property {Object} [methods]
 * @property {Object} [state]
 * @property {Array} [processing]
 * @property {*} [async]
 * @property {Array} [above]
 * @property {Boolean} [asyncDetected]
 * @property {*} [values]
 * @property {*} [yieldUsed]
 * @property {Boolean} [useContext]
 * @property {Boolean} [avoidInlineAsync]
 *
 */

/**
 * Checks if the value passed in is a primitive JS object / value.
 * @param {*} x
 * @returns
 */
function isPrimitive (x, preserveObject) {
  if (typeof x === 'number' && (x === Infinity || x === -Infinity || Number.isNaN(x))) return false
  return (
    x === null ||
    x === undefined ||
    ['Number', 'String', 'Boolean'].includes(x.constructor.name) ||
    (!preserveObject && x.constructor.name === 'Object')
  )
}

/**
 * Checks if the method & its inputs are deterministic.
 * @param {*} method
 * @param {*} engine
 * @param {BuildState} buildState
 * @returns
 */
function isDeterministic$2 (method, engine, buildState) {
  if (Array.isArray(method)) {
    return method.every((i) => isDeterministic$2(i, engine, buildState))
  }

  if (method && typeof method === 'object') {
    const func = Object.keys(method)[0]
    const lower = method[func]

    if (engine.methods[func].traverse === false) {
      return typeof engine.methods[func].deterministic === 'function'
        ? engine.methods[func].deterministic(lower, buildState)
        : engine.methods[func].deterministic
    }

    return typeof engine.methods[func].deterministic === 'function'
      ? engine.methods[func].deterministic(lower, buildState)
      : engine.methods[func].deterministic &&
          isDeterministic$2(lower, engine, buildState)
  }

  return true
}

/**
 * Checks if the method & its inputs are synchronous.
 * @param {*} method
 * @param {*} engine
 */
function isDeepSync (method, engine) {
  if (!engine.async) return true

  if (Array.isArray(method)) {
    return method.every(i => isDeepSync(i, engine))
  }

  if (typeof method === 'object') {
    const func = Object.keys(method)[0]

    const lower = method[func]
    if (!isSync(engine.methods[func])) return false
    return isDeepSync(lower, engine)
  }

  return true
}

/**
 * A function that handles yields by caching the values to resumable object.
 * @param {Function} func
 * @param {*} input
 * @param {String} name
 * @param {Object} resumable
 * @returns
 */
function r (func, input, name, resumable) {
  if (resumable[name]) {
    return resumable[name]
  }
  const result = resumable[name + '_input']
    ? func(resumable[name + '_input'])
    : func(typeof input === 'function' ? input() : input)

  if (result instanceof YieldStructure) {
    if (result._input) {
      resumable[name + '_input'] = result._input
    }
    result.resumable = resumable
    throw result
  } else {
    resumable[name] = result
  }

  return result
}

/**
 * A function that handles async yields by caching the values to resumable object.
 * @param {Function} func
 * @param {*} input
 * @param {String} name
 * @param {Object} resumable
 * @returns
 */
async function rAsync (func, input, name, resumable) {
  if (resumable[name]) {
    return resumable[name]
  }

  const result = resumable[name + '_input']
    ? await func(resumable[name + '_input'])
    : await func(typeof input === 'function' ? await input() : input)

  if (result instanceof YieldStructure) {
    if (result._input) {
      resumable[name + '_input'] = result._input
    }

    result.resumable = resumable
    throw result
  } else {
    resumable[name] = result
  }

  return result
}
/**
 * Builds a string for a function that may need to yield & resume.
 * @param {String} method
 * @param {BuildState} buildState
 * @returns
 */
function buildYield (method, buildState = {}) {
  // todo: add gc so we don't save resumable state for longer than it needs to exist
  const { notTraversed = [], functions = {}, async, engine } = buildState
  const func = Object.keys(method)[0]
  buildState.yieldUsed = (buildState.yieldUsed || 0) + 1
  let asyncDetected = false
  buildState.useContext =
    buildState.useContext || (engine.methods[func] || {}).useContext

  if (typeof engine.methods[func] === 'function') {
    functions[func] = 1
    asyncDetected = !isSync(engine.methods[func])
    const stringBuildState = { ...buildState, avoidInlineAsync: true }
    const inputStr = buildString(method[func], stringBuildState)
    buildState.useContext =
      buildState.useContext || stringBuildState.useContext

    if (asyncDetected || inputStr.includes('await')) {
      buildState.asyncDetected = buildState.asyncDetected || asyncDetected
      return `await rAsync(gen["${func}"], async () => { return ${inputStr} }, 'yield${buildState.yieldUsed}', resumable)`
    }

    return `r(gen["${func}"], () => { return ${inputStr} }, 'yield${buildState.yieldUsed}', resumable)`
  } else {
    if (engine.methods[func] && engine.methods[func].traverse) {
      functions[func] = 1
      // console.log(async)
      asyncDetected = Boolean(
        async && engine.methods[func] && engine.methods[func].asyncMethod
      )

      const stringBuildState = { ...buildState, avoidInlineAsync: true }
      const inputStr = buildString(method[func], stringBuildState)

      buildState.useContext =
        buildState.useContext || stringBuildState.useContext

      if (asyncDetected || inputStr.startsWith('await')) {
        buildState.asyncDetected = buildState.asyncDetected || asyncDetected
        return `await rAsync(gen["${func}"], async () => ${inputStr}, 'yield${buildState.yieldUsed}', resumable)`
      }
      return `r(gen["${func}"], () => ${inputStr}, 'yield${buildState.yieldUsed}', resumable)`
    } else {
      // todo: make build work for yields somehow. The issue is that it pre-binds data, thus making it impossible
      asyncDetected = Boolean(
        async && engine.methods[func] && engine.methods[func].asyncMethod
      )
      functions[func] = 1
      notTraversed.push(method[func])
      buildState.useContext = true

      if (asyncDetected) {
        buildState.asyncDetected = buildState.asyncDetected || asyncDetected
        return `await rAsync(gen["${func}"], notTraversed[${
          notTraversed.length - 1
        }], 'yield${buildState.yieldUsed}', resumable)`
      }
      return `r(gen["${func}"], notTraversed[${
        notTraversed.length - 1
      }], 'yield${buildState.yieldUsed}', resumable)`
    }
  }
}
/**
 * Builds the string for the function that will be evaluated.
 * @param {*} method
 * @param {BuildState} buildState
 * @returns
 */
function buildString (method, buildState = {}) {
  const {
    notTraversed = [],
    functions = {},
    methods = [],
    state,
    async,
    above = [],
    processing = [],
    values = [],
    engine
  } = buildState
  function pushValue (value, preserveObject = false) {
    if (isPrimitive(value, preserveObject)) return JSON.stringify(value)
    values.push(value)
    return `values[${values.length - 1}]`
  }

  if (Array.isArray(method)) {
    return '[' + method.map((i) => buildString(i, buildState)).join(', ') + ']'
  }

  let asyncDetected = false

  function makeAsync (result) {
    buildState.asyncDetected = buildState.asyncDetected || asyncDetected

    if (async && asyncDetected) {
      return `await ${result}`
    }
    return result
  }

  const func = method && Object.keys(method)[0]
  buildState.useContext =
    buildState.useContext || (engine.methods[func] || {}).useContext

  if (method && typeof method === 'object') {
    if (!func) return pushValue(method)
    if (!engine.methods[func]) {
      // If we are in permissive mode, we will just return the object.
      if (engine.options.permissive) return pushValue(method, true)
      throw new Error(`Method '${func}' was not found in the Logic Engine.`)
    }
    functions[func] = functions[func] || 2

    if (
      !buildState.engine.disableInline &&
      engine.methods[func] &&
      isDeterministic$2(method, engine, buildState)
    ) {
      if (isDeepSync(method, engine)) {
        return pushValue((engine.fallback || engine).run(method), true)
      } else if (!buildState.avoidInlineAsync) {
        processing.push(engine.run(method).then((i) => pushValue(i)))
        return `__%%%${processing.length - 1}%%%__`
      }
    }

    if (
      engine.options.yieldSupported &&
      engine.methods[func] &&
      engine.methods[func].yields
    ) {
      return buildYield(method, buildState)
    }

    if (engine.methods[func] && engine.methods[func].compile) {
      const str = engine.methods[func].compile(method[func], buildState)

      if ((str || '').startsWith('await')) buildState.asyncDetected = true

      if (str !== false) return str
    }

    if (typeof engine.methods[func] === 'function') {
      functions[func] = 1
      asyncDetected = !isSync(engine.methods[func])

      return makeAsync(
        `gen["${func}"](` + buildString(method[func], buildState) + ')'
      )
    } else {
      if (engine.methods[func] && (typeof engine.methods[func].traverse === 'undefined' ? true : engine.methods[func].traverse)) {
        functions[func] = 1
        asyncDetected = Boolean(
          async && engine.methods[func] && engine.methods[func].asyncMethod
        )

        return makeAsync(
          `gen["${func}"](` + buildString(method[func], buildState) + ')'
        )
      } else {
        if (engine.methods[func]) {
          if (async) {
            if (engine.methods[func].asyncBuild || engine.methods[func].build) {
              const builder =
                engine.methods[func].asyncBuild || engine.methods[func].build
              const result = builder(
                method[func],
                state,
                above,
                engine,
                buildState
              )
              methods.push(result)
              asyncDetected = !isSync(result)
              return makeAsync(`methods[${methods.length - 1}]()`)
            }
          } else {
            if (engine.methods[func].build) {
              methods.push(
                engine.methods[func].build(
                  method[func],
                  state,
                  above,
                  engine,
                  buildState
                )
              )
              return makeAsync(`methods[${methods.length - 1}]()`)
            }
          }
        }

        asyncDetected = Boolean(
          async && engine.methods[func] && engine.methods[func].asyncMethod
        )

        functions[func] = 1
        notTraversed.push(method[func])

        return makeAsync(
          `gen["${func}"](` + `notTraversed[${notTraversed.length - 1}]` + ')'
        )
      }
    }
  }
  return pushValue(method)
}

/**
 * Synchronously compiles the logic to a function that can run the logic more optimally.
 * @param {*} method
 * @param {BuildState} [buildState]
 * @returns
 */
function build (method, buildState = {}) {
  Object.assign(
    buildState,
    Object.assign(
      {
        notTraversed: [],
        functions: {},
        methods: [],
        state: {},
        processing: [],
        async: buildState.engine.async,
        above: [],
        asyncDetected: false,
        values: []
      },
      buildState
    )
  )
  const str = buildString(method, buildState)
  return processBuiltString(method, str, buildState)
}

/**
 * Asynchronously compiles the logic to a function that can run the logic more optimally. Also supports async logic methods.
 * @param {*} method
 * @param {BuildState} [buildState]
 * @returns
 */
async function buildAsync (method, buildState = {}) {
  Object.assign(
    buildState,
    Object.assign(
      {
        notTraversed: [],
        functions: {},
        methods: [],
        state: {},
        processing: [],
        async: buildState.engine.async,
        above: [],
        asyncDetected: false,
        values: []
      },
      buildState
    )
  )
  const str = buildString(method, buildState)
  buildState.processing = await Promise.all(buildState.processing)
  return processBuiltString(method, str, buildState)
}

/**
 * Takes the string that's been generated and does some post-processing on it to be evaluated.
 * @param {*} method
 * @param {*} str
 * @param {BuildState} buildState
 * @returns
 */
function processBuiltString (method, str, buildState) {
  const gen = {}
  const {
    functions,
    state,
    async,
    engine,
    above,
    methods,
    notTraversed,
    processing,
    values
  } = buildState
  processing.forEach((item, x) => {
    str = str.replace(`__%%%${x}%%%__`, item)
  })
  Object.keys(functions).forEach((key) => {
    if (functions[key] === 2) return

    if (!engine.methods[key]) throw new Error(`Method '${key}' was not found in the Logic Engine.`)

    if (typeof engine.methods[key] === 'function') {
      const method = engine.methods[key]
      gen[key] = (input) => method(input, state, above, engine)
    } else {
      if (async && engine.methods[key].asyncMethod) {
        buildState.asyncDetected = true
        const method = engine.methods[key].asyncMethod
        gen[key] = (input) => method(input, state, above, engine)
      } else {
        const method = engine.methods[key].method
        gen[key] = (input) => method(input, state, above, engine)
      }
    }
  })

  if (!Object.keys(functions).length) {
    return method
  }

  let copyStateCall = 'state[Override] = context;'
  // console.log(buildState.useContext)

  if (!buildState.useContext) {
    copyStateCall = ''
    while (str.includes('state[Override]')) {
      str = str.replace('state[Override]', 'context')
    }
  }

  const final = `(state, values, methods, gen, notTraversed, Override, asyncIterators, r, rAsync) => ${buildState.asyncDetected ? 'async' : ''} (context ${
    buildState.yieldUsed ? ', resumable = {}' : ''
  }) => { ${copyStateCall} const result = ${str}; return result }`

  // console.log(str)
  // console.log(final)
  // eslint-disable-next-line no-eval
  return declareSync(globalThis.eval(final)(state, values, methods, gen, notTraversed, Override, asyncIterators, r, rAsync), !buildState.asyncDetected)
}
const Compiler = {
  build,
  buildAsync,
  buildString,
  r,
  rAsync
}

// @ts-check
/**
 * Checks if optional chaining is supported for the compiler
 * @returns {Boolean}
 */
const getIsOptionalChainingSupported = () => {
  try {
    // eslint-disable-next-line no-unused-vars
    const test = {}
    // eslint-disable-next-line no-eval
    const isUndefined = globalThis.eval('(test) => test?.foo?.bar')(test)
    return isUndefined === undefined
  } catch {
    return false
  }
}
const chainingSupported = getIsOptionalChainingSupported()

// @ts-check
class InvalidControlInput extends Error {
  constructor (input) {
    super()
    this.message =
      'Built-in control structures are not allowed to receive dynamic inputs, this could allow a lesser version of remote-code execution.'
    this.input = input
  }
}

// @ts-check

// works fine for arrays, may need to create a more general version though
class ReduceIterator {
  constructor (arr, cur, next) {
    this.arr = arr
    this.cur = cur
    this.nextCall = next
    this.position = 0
    this._position = 0
    this.map = null
  }

  next () {
    const item = this.arr[this.position]
    this._position = this.position
    const cur = this.nextCall(this.cur, item, this.arr, this)
    if (cur instanceof YieldStructure || cur instanceof EngineObject) {
      return cur
    }
    // commit
    this.position = this._position
    this.cur = cur
    this.position++
    return this.cur
  }

  skip () {
    this._position++
  }

  dump () {
    this._position = this.arr.length
  }

  result () {
    return this.cur
  }

  state () {
    return { arr: this.arr.splice(this.position), cur: this.cur }
  }

  done () {
    return this.position >= this.arr.length
  }
}
class AsyncReduceIterator extends ReduceIterator {
  async next () {
    const item = this.arr[this.position]
    this._position = this.position
    const cur = await this.nextCall(this.cur, item, this.arr, this)
    if (cur instanceof YieldStructure || cur instanceof EngineObject) {
      return cur
    }
    // commit
    this.position = this._position
    this.cur = cur
    this.position++
    return this.cur
  }
}

// @ts-check

// Todo: Pursue support for yielding within the built functions.
// It will be extremely difficult to leverage the yields here.
function isDeterministic$1 (method, engine, buildState) {
  if (Array.isArray(method)) {
    return method.every((i) => isDeterministic$1(i, engine, buildState))
  }
  if (method && typeof method === 'object') {
    const func = Object.keys(method)[0]
    const lower = method[func]
    if (engine.methods[func].traverse === false) {
      return typeof engine.methods[func].deterministic === 'function'
        ? engine.methods[func].deterministic(lower, buildState)
        : engine.methods[func].deterministic
    }
    return typeof engine.methods[func].deterministic === 'function'
      ? engine.methods[func].deterministic(lower, buildState)
      : engine.methods[func].deterministic &&
          isDeterministic$1(lower, engine, buildState)
  }
  return true
}
function createYieldingControl (name, method, asyncMethod) {
  return {
    yields: true,
    method: (input, context, above, engine) => {
      let arr = input
      let cur = null
      if (!Array.isArray(input)) {
        arr = input.arr
        cur = input.cur
      }
      const executed = method(input, context, above, engine)
      const iter = new ReduceIterator(arr, cur, executed)
      while (!iter.done()) {
        const cur = iter.next()
        if (checkYield(cur)) {
          return new YieldStructure({
            yield: cur,
            _name: name,
            _input: iter.state()
          })
        }
      }
      return iter.result()
    },
    asyncMethod: async (input, context, above, engine) => {
      let arr = input
      let cur = null
      if (!Array.isArray(input)) {
        arr = input.arr
        cur = input.cur
      }
      const executed = asyncMethod(input, context, above, engine)
      const iter = new AsyncReduceIterator(arr, cur, executed)
      while (!iter.done()) {
        const cur = await iter.next()
        if (checkYield(cur)) {
          return new YieldStructure({
            yield: cur,
            _name: name,
            _input: iter.state()
          })
        }
      }
      return iter.result()
    },
    traverse: false
  }
}
const ifYield = createYieldingControl(
  'ifYield',
  (input, context, above, engine) => (cur, item, arr, iter) => {
    if (arr.length % 2 === 0) {
      arr.push(null)
    }
    // if it's an even spot, and not the last item, run the condition.
    if ((iter._position & 1) === 0 && iter._position !== arr.length - 1) {
      const test = engine.run(item, context, {
        above
      })
      if (!test) {
        iter.skip()
      }
      return test
    } else if (iter._position & 1) {
      // if it's odd, then we are done.
      iter.dump()
    }

    return engine.run(item, context, {
      above
    })
  },
  (input, context, above, engine) => async (cur, item, arr, iter) => {
    if (arr.length % 2 === 0) {
      arr.push(null)
    }
    // if it's an even spot, and not the last item, run the condition.
    if ((iter._position & 1) === 0 && iter._position !== arr.length - 1) {
      const test = await engine.run(item, context, {
        above
      })
      if (!test) {
        iter.skip()
      }
      return test
    } else if (iter._position & 1) {
      // if it's odd, then we are done.
      iter.dump()
    }

    return engine.run(item, context, {
      above
    })
  }
)
const someYield = createArrayIterativeMethod$1(
  'someYield',
  (input, context, above, engine) => (cur, item, arr, iter) => {
    const currentItem = engine.run(iter.map, item, {
      above: [input, context, ...above]
    })
    if (currentItem) {
      iter.dump()
      return currentItem
    }
    return false
  },
  (input, context, above, engine) => async (cur, item, arr, iter) => {
    const currentItem = await engine.run(iter.map, item, {
      above: [input, context, ...above]
    })
    if (currentItem) {
      iter.dump()
      return currentItem
    }
    return false
  },
  false
)
const everyYield = createArrayIterativeMethod$1(
  'everyYield',
  (input, context, above, engine) => (cur, item, arr, iter) => {
    const currentItem = engine.run(iter.map, item, {
      above: [input, context, ...above]
    })
    if (!currentItem) {
      iter.dump()
      return false
    }
    return currentItem
  },
  (input, context, above, engine) => async (cur, item, arr, iter) => {
    const currentItem = await engine.run(iter.map, item, {
      above: [input, context, ...above]
    })
    if (!currentItem) {
      iter.dump()
      return false
    }
    return currentItem
  },
  true
)
const filterYield = createArrayIterativeMethod$1(
  'filterYield',
  (input, context, above, engine) => (cur, item, arr, iter) => {
    const currentItem = engine.run(iter.map, item, {
      above: [input, context, ...above]
    })
    if (checkYield(currentItem)) return currentItem
    if (currentItem) cur.push(item)
    return cur
  },
  (input, context, above, engine) => async (cur, item, arr, iter) => {
    const currentItem = await engine.run(iter.map, item, {
      above: [input, context, ...above]
    })
    if (checkYield(currentItem)) return currentItem
    if (currentItem) cur.push(item)
    return cur
  },
  () => []
)
const mapYield = createArrayIterativeMethod$1(
  'mapYield',
  (input, context, above, engine) => (cur, item, arr, iter) => {
    const currentItem = engine.run(iter.map, item, {
      above: [input, context, ...above]
    })
    if (checkYield(currentItem)) return currentItem
    cur.push(currentItem)
    return cur
  },
  (input, context, above, engine) => async (cur, item, arr, iter) => {
    const currentItem = await engine.run(iter.map, item, {
      above: [input, context, ...above]
    })
    if (checkYield(currentItem)) return currentItem
    cur.push(currentItem)
    return cur
  },
  () => []
)
const reduceYield = createArrayIterativeMethod$1(
  'reduceYield',
  (input, context, above, engine) => (cur, item, arr, iter) => {
    return engine.run(
      iter.map,
      {
        accumulator: cur,
        current: item
      },
      {
        above: [input, context, ...above]
      }
    )
  },
  (input, context, above, engine) => async (cur, item, arr, iter) => {
    return engine.run(
      iter.map,
      {
        accumulator: cur,
        current: item
      },
      {
        above: [input, context, ...above]
      }
    )
  }
)
function createArrayIterativeMethod$1 (
  name,
  method,
  asyncMethod,
  defaultInitializer
) {
  const result = {
    yields: true,
    deterministic: (data, buildState) => {
      return (
        isDeterministic$1(data[0], buildState.engine, buildState) &&
        isDeterministic$1(data[1], buildState.engine, {
          ...buildState,
          insideIterator: true
        })
      )
    },
    build: (input, context, above, engine, buildState) => {
      buildState.useContext = true
      return declareSync(() => result.method(input, context, above, engine))
    },
    asyncBuild: (input, context, above, engine, buildState) => {
      buildState.useContext = true
      const [selector, mapper] = input
      // const selectFunction = engine.build(selector, {}, { top: EfficientTop })
      // const mapFunction = engine.build(mapper, {}, { top: EfficientTop })
      const selectFunction = build(selector, {
        engine,
        async: true,
        avoidInlineAsync: true
      })
      const mapFunction = build(mapper, {
        engine,
        state: {},
        async: true,
        avoidInlineAsync: true
      })
      if (isSync(selectFunction) && isSync(mapFunction)) {
        return declareSync(() =>
          result.method(input, context, above, engine.fallback)
        )
      }
      return () => result.asyncMethod(input, context, above, engine)
    },
    method: (input, context, above, engine) => {
      let defaultCur = defaultInitializer
      if (typeof defaultInitializer === 'function') {
        defaultCur = defaultInitializer()
      }
      let arr
      let cur
      let map = null
      if (Array.isArray(input)) {
        const [selector, mapper, defaultValue] = input
        const selected =
          engine.run(selector, context, {
            above
          }) || []
        if (checkYield(selected)) {
          // todo: add extraction of the existing yields.
          return new YieldStructure({
            _input: [selector, mapper, defaultValue],
            _name: name,
            yields: selected.yields()
          })
        }
        arr = selected
        map = mapper
        cur = defaultValue === 0 ? 0 : defaultValue || defaultCur
      } else {
        arr = input.arr
        cur = input.cur
        map = input.map
      }
      const executed = method(input, context, above, engine)
      const iter = new ReduceIterator(arr, cur, executed)
      iter.map = map
      while (!iter.done()) {
        const cur = iter.next()
        if (checkYield(cur)) {
          return new YieldStructure({
            yields: cur.yields(),
            _name: name,
            _input: {
              ...iter.state(),
              map
            }
          })
        }
      }
      return iter.result()
    },
    asyncMethod: async (input, context, above, engine) => {
      let defaultCur = defaultInitializer
      if (typeof defaultInitializer === 'function') {
        defaultCur = defaultInitializer()
      }
      let arr
      let cur
      let map = null
      if (Array.isArray(input)) {
        const [selector, mapper, defaultValue] = input
        const selected =
          (await engine.run(selector, context, {
            above
          })) || []
        if (checkYield(selected)) {
          // todo: add extraction of the existing yields.
          return new YieldStructure({
            _name: name,
            _input: [selector, mapper, defaultValue],
            yields: selected.yields()
          })
        }
        arr = selected
        map = mapper
        cur = defaultValue === 0 ? 0 : defaultValue || defaultCur
      } else {
        arr = input.arr
        cur = input.cur
        map = input.map
      }
      const executed = asyncMethod(input, context, above, engine)
      const iter = new AsyncReduceIterator(arr, cur, executed)
      iter.map = map
      while (!iter.done()) {
        const cur = await iter.next()
        if (checkYield(cur)) {
          return new YieldStructure({
            yields: cur.yields(),
            _name: name,
            _input: {
              ...iter.state(),
              map
            }
          })
        }
      }
      return iter.result()
    },
    traverse: false
  }
  return result
}
const YieldingIterators = {
  someYield,
  everyYield,
  filterYield,
  mapYield,
  reduceYield,
  ifYield
}

/**
 * Splits a path string into an array of parts.
 *
 * @example splitPath('a.b.c') // ['a', 'b', 'c']
 * @example splitPath('a\\.b.c') // ['a.b', 'c']
 * @example splitPath('a\\\\.b.c') // ['a\\', 'b', 'c']
 * @example splitPath('a\\\\\\.b.c') // ['a\\.b', 'c']
 * @example splitPath('hello') // ['hello']
 * @example splitPath('hello\\') // ['hello\\']
 * @example splitPath('hello\\\\') // ['hello\\']
 *
 * @param {string} str
 * @param {string} separator
 * @returns {string[]}
 */
function splitPath (str, separator = '.', escape = '\\') {
  const parts = []
  let current = ''

  for (let i = 0; i < str.length; i++) {
    const char = str[i]
    if (char === escape) {
      if (str[i + 1] === separator) {
        current += separator
        i++
      } else if (str[i + 1] === escape) {
        current += escape
        i++
      } else current += escape
    } else if (char === separator) {
      parts.push(current)
      current = ''
    } else current += char
  }
  parts.push(current)

  return parts
}

// @ts-check

function isDeterministic (method, engine, buildState) {
  if (Array.isArray(method)) {
    return method.every((i) => isDeterministic(i, engine, buildState))
  }
  if (method && typeof method === 'object') {
    const func = Object.keys(method)[0]
    const lower = method[func]

    if (!engine.methods[func] && engine.options.permissive) return true

    if (engine.methods[func].traverse === false) {
      return typeof engine.methods[func].deterministic === 'function'
        ? engine.methods[func].deterministic(lower, buildState)
        : engine.methods[func].deterministic
    }
    return typeof engine.methods[func].deterministic === 'function'
      ? engine.methods[func].deterministic(lower, buildState)
      : engine.methods[func].deterministic &&
          isDeterministic(lower, engine, buildState)
  }
  return true
}

const defaultMethods = {
  '+': (data) => [].concat(data).reduce((a, b) => +a + +b, 0),
  '*': (data) => data.reduce((a, b) => +a * +b),
  '/': (data) => data.reduce((a, b) => +a / +b),

  '-': (data) =>
  // @ts-ignore Type checking is incorrect on the following line.
    ((a) => (a.length === 1 ? (a[0] = -a[0]) : a) & 0 || a)(
      [].concat(data)
      // @ts-ignore Type checking is incorrect on the following line.
    ).reduce((a, b) => +a - +b),
  '%': (data) => data.reduce((a, b) => +a % +b),
  max: (data) => Math.max(...data),
  min: (data) => Math.min(...data),
  in: ([item, array]) => (array || []).includes(item),
  '>': ([a, b]) => a > b,
  '<': ([a, b, c]) => (c === undefined ? a < b : a < b && b < c),
  preserve: {
    traverse: false,
    method: declareSync((i) => i)
  },
  if: {
    method: (input, context, above, engine) => {
      if (!Array.isArray(input)) throw new InvalidControlInput(input)

      // check the bounds
      if (input.length < 2) {
        throw new InvalidControlInput(input)
      }

      input = [...input]

      if (input.length % 2 !== 1) {
        input.push(null)
      }

      // fallback to the default if the condition is false
      const onFalse = input.pop()

      // while there are still conditions
      while (input.length) {
        const check = input.shift()
        const onTrue = input.shift()

        const test = engine.run(check, context, {
          above
        })

        // if the condition is true, run the true branch
        if (test) {
          return engine.run(onTrue, context, {
            above
          })
        }
      }

      return engine.run(onFalse, context, {
        above
      })
    },
    deterministic: (data, buildState) => {
      return isDeterministic(data, buildState.engine, buildState)
    },
    asyncMethod: async (input, context, above, engine) => {
      if (!Array.isArray(input)) throw new InvalidControlInput(input)

      // check the bounds
      if (input.length < 2) {
        throw new InvalidControlInput(input)
      }

      input = [...input]

      if (input.length % 2 !== 1) {
        input.push(null)
      }

      // fallback to the default if the condition is false
      const onFalse = input.pop()

      // while there are still conditions
      while (input.length) {
        const check = input.shift()
        const onTrue = input.shift()

        const test = await engine.run(check, context, {
          above
        })

        // if the condition is true, run the true branch
        if (test) {
          return engine.run(onTrue, context, {
            above
          })
        }
      }

      return engine.run(onFalse, context, {
        above
      })
    },
    traverse: false
  },
  '<=': ([a, b, c]) => (c === undefined ? a <= b : a <= b && b <= c),
  '>=': ([a, b]) => a >= b,
  // eslint-disable-next-line eqeqeq
  '==': ([a, b]) => a == b,
  '===': ([a, b]) => a === b,
  // eslint-disable-next-line eqeqeq
  '!=': ([a, b]) => a != b,
  '!==': ([a, b]) => a !== b,
  xor: ([a, b]) => a ^ b,
  or: (arr) => arr.reduce((a, b) => a || b, false),
  and: (arr) => arr.reduce((a, b) => a && b),
  substr: ([string, from, end]) => {
    if (end < 0) {
      const result = string.substr(from)
      return result.substr(0, result.length + end)
    }
    return string.substr(from, end)
  },
  length: (i) => {
    if (typeof i === 'string' || Array.isArray(i)) return i.length
    if (i && typeof i === 'object') return Object.keys(i).length
    return 0
  },
  get: {
    method: ([data, key, defaultValue], context, above, engine) => {
      const notFound = defaultValue === undefined ? null : defaultValue

      const subProps = splitPath(String(key))
      for (let i = 0; i < subProps.length; i++) {
        if (data === null || data === undefined) {
          return notFound
        }
        // Descending into context
        data = data[subProps[i]]
        if (data === undefined) {
          return notFound
        }
      }
      if (engine.allowFunctions || typeof data[key] !== 'function') {
        return data
      }
    }
  },
  var: (key, context, above, engine) => {
    let b
    if (Array.isArray(key)) {
      b = key[1]
      key = key[0]
    }
    // if (!key && context && context[Override]) return context[Override]
    let iter = 0
    while (
      typeof key === 'string' &&
      key.startsWith('../') &&
      iter < above.length
    ) {
      context = above[iter++]
      key = key.substring(3)
    }
    if (context && typeof context[Override] !== 'undefined') {
      context = context[Override]
    }
    const notFound = b === undefined ? null : b
    if (typeof key === 'undefined' || key === '' || key === null) {
      if (engine.allowFunctions || typeof (context && context[key]) !== 'function') {
        return context
      }
      return null
    }
    const subProps = splitPath(String(key))
    for (let i = 0; i < subProps.length; i++) {
      if (context === null || context === undefined) {
        return notFound
      }
      // Descending into context
      context = context[subProps[i]]
      if (context === undefined) {
        return notFound
      }
    }
    if (engine.allowFunctions || typeof (context && context[key]) !== 'function') {
      return context
    }
    return null
  },
  missing: (checked, context, above, engine) => {
    return (Array.isArray(checked) ? checked : [checked]).filter((key) => {
      return defaultMethods.var(key, context, above, engine) === null
    })
  },
  missing_some: ([needCount, options], context, above, engine) => {
    const missing = defaultMethods.missing(options, context, above, engine)
    if (options.length - missing.length >= needCount) {
      return []
    } else {
      return missing
    }
  },
  map: createArrayIterativeMethod('map'),
  some: createArrayIterativeMethod('some'),
  all: createArrayIterativeMethod('every'),
  none: {
    traverse: false,
    // todo: add async build & build
    method: (val, context, above, engine) => {
      return !defaultMethods.some.method(val, context, above, engine)
    },
    asyncMethod: async (val, context, above, engine) => {
      return !(await defaultMethods.some.asyncMethod(
        val,
        context,
        above,
        engine
      ))
    },
    compile: (data, buildState) => {
      const result = `${defaultMethods.some.compile(data, buildState)}`
      return result ? `!(${result})` : false
    }
  },
  merge: (arrays) => (Array.isArray(arrays) ? [].concat(...arrays) : [arrays]),
  every: createArrayIterativeMethod('every'),
  filter: createArrayIterativeMethod('filter'),
  reduce: {
    deterministic: (data, buildState) => {
      return (
        isDeterministic(data[0], buildState.engine, buildState) &&
        isDeterministic(data[1], buildState.engine, {
          ...buildState,
          insideIterator: true
        })
      )
    },
    compile: (data, buildState) => {
      if (!Array.isArray(data)) throw new InvalidControlInput(data)
      const { above = [], state, async } = buildState
      let [selector, mapper, defaultValue] = data
      selector = buildString(selector, buildState)
      if (typeof defaultValue !== 'undefined') {
        defaultValue = buildString(defaultValue, buildState)
      }
      const mapState = {
        ...buildState,
        state: {},
        above: [selector, state, ...above],
        avoidInlineAsync: true
      }
      mapper = build(mapper, mapState)
      buildState.useContext = buildState.useContext || mapState.useContext
      buildState.methods.push(mapper)
      if (async) {
        if (!isSync(mapper) || selector.includes('await')) {
          buildState.detectAsync = true
          if (typeof defaultValue !== 'undefined') {
            return `await asyncIterators.reduce(${selector} || [], (a,b) => methods[${
              buildState.methods.length - 1
            }]({ accumulator: a, current: b }), ${defaultValue})`
          }
          return `await asyncIterators.reduce(${selector} || [], (a,b) => methods[${
            buildState.methods.length - 1
          }]({ accumulator: a, current: b }))`
        }
      }
      if (typeof defaultValue !== 'undefined') {
        return `(${selector} || []).reduce((a,b) => methods[${
          buildState.methods.length - 1
        }]({ accumulator: a, current: b }), ${defaultValue})`
      }
      return `(${selector} || []).reduce((a,b) => methods[${
        buildState.methods.length - 1
      }]({ accumulator: a, current: b }))`
    },
    method: (input, context, above, engine) => {
      if (!Array.isArray(input)) throw new InvalidControlInput(input)
      let [selector, mapper, defaultValue] = input
      defaultValue = engine.run(defaultValue, context, {
        above
      })
      selector =
        engine.run(selector, context, {
          above
        }) || []
      const func = (accumulator, current) => {
        return engine.run(
          mapper,
          {
            accumulator,
            current
          },
          {
            above: [selector, context, ...above]
          }
        )
      }
      if (typeof defaultValue === 'undefined') {
        return selector.reduce(func)
      }
      return selector.reduce(func, defaultValue)
    },
    asyncMethod: async (input, context, above, engine) => {
      if (!Array.isArray(input)) throw new InvalidControlInput(input)
      let [selector, mapper, defaultValue] = input
      defaultValue = await engine.run(defaultValue, context, {
        above
      })
      selector =
        (await engine.run(selector, context, {
          above
        })) || []
      return asyncIterators.reduce(
        selector,
        (accumulator, current) => {
          return engine.run(
            mapper,
            {
              accumulator,
              current
            },
            {
              above: [selector, context, ...above]
            }
          )
        },
        defaultValue
      )
    },
    traverse: false
  },
  not: (value) => !value,
  '!': (value) => !value,
  '!!': (value) => Boolean(value),
  cat: (arr) => (typeof arr === 'string' ? arr : arr.join('')),
  keys: (obj) => Object.keys(obj),
  eachKey: {
    traverse: false,
    method: (object, context, above, engine) => {
      const result = Object.keys(object).reduce((accumulator, key) => {
        const item = object[key]
        Object.defineProperty(accumulator, key, {
          enumerable: true,
          value: engine.run(item, context, { above })
        })
        return accumulator
      }, {})
      return result
    },
    useContext: true,
    deterministic: (data, buildState) => {
      if (data && typeof data === 'object') {
        return Object.values(data).every((i) => {
          return isDeterministic(i, buildState.engine, buildState)
        })
      }
      throw new InvalidControlInput(data)
    },
    compile: (data, buildState) => {
      // what's nice about this is that I don't have to worry about whether it's async or not, the lower entries take care of that ;)
      // however, this is not engineered support yields, I will have to make a note of that & possibly support it at a later point.
      if (data && typeof data === 'object') {
        const result = `({ ${Object.keys(data)
          .reduce((accumulator, key) => {
            accumulator.push(
              `${JSON.stringify(key)}: ${buildString(data[key], buildState)}`
            )
            return accumulator
          }, [])
          .join(',')} })`
        return result
      }
      throw new InvalidControlInput(data)
    },
    asyncMethod: async (object, context, above, engine) => {
      const result = await asyncIterators.reduce(
        Object.keys(object),
        async (accumulator, key) => {
          const item = object[key]
          Object.defineProperty(accumulator, key, {
            enumerable: true,
            value: await engine.run(item, context, { above })
          })
          return accumulator
        },
        {}
      )
      return result
    }
  }
}
function createArrayIterativeMethod (name) {
  return {
    deterministic: (data, buildState) => {
      return (
        isDeterministic(data[0], buildState.engine, buildState) &&
        isDeterministic(data[1], buildState.engine, {
          ...buildState,
          insideIterator: true
        })
      )
    },
    method: (input, context, above, engine) => {
      if (!Array.isArray(input)) throw new InvalidControlInput(input)
      let [selector, mapper] = input
      selector =
        engine.run(selector, context, {
          above
        }) || []
      return selector[name]((i) => {
        return engine.run(mapper, i, {
          above: [selector, context, ...above]
        })
      })
    },
    asyncMethod: async (input, context, above, engine) => {
      if (!Array.isArray(input)) throw new InvalidControlInput(input)
      let [selector, mapper] = input
      selector =
        (await engine.run(selector, context, {
          above
        })) || []
      return asyncIterators[name](selector, (i) => {
        return engine.run(mapper, i, {
          above: [selector, context, ...above]
        })
      })
    },
    compile: (data, buildState) => {
      if (!Array.isArray(data)) throw new InvalidControlInput(data)
      const { above = [], state, async } = buildState
      let [selector, mapper] = data
      selector = buildString(selector, buildState)
      const mapState = {
        ...buildState,
        state: {},
        above: [selector, state, ...above],
        avoidInlineAsync: true
      }
      mapper = build(mapper, mapState)
      buildState.useContext = buildState.useContext || mapState.useContext
      buildState.methods.push(mapper)
      if (async) {
        if (!isSync(mapper) || selector.includes('await')) {
          buildState.detectAsync = true
          return `await asyncIterators.${name}(${selector} || [], methods[${
            buildState.methods.length - 1
          }])`
        }
      }
      return `(${selector} || []).${name}(methods[${
        buildState.methods.length - 1
      }])`
    },
    traverse: false
  }
}
defaultMethods['?:'] = defaultMethods.if
// declare all of the functions here synchronous
Object.keys(defaultMethods).forEach((item) => {
  if (typeof defaultMethods[item] === 'function') {
    defaultMethods[item][Sync] = true
  }
  defaultMethods[item].deterministic =
    typeof defaultMethods[item].deterministic === 'undefined'
      ? true
      : defaultMethods[item].deterministic
})
// @ts-ignore Allow custom attribute
defaultMethods.var.deterministic = (data, buildState) => {
  return buildState.insideIterator && !String(data).includes('../')
}
Object.assign(defaultMethods.var, { traverse: false })
Object.assign(defaultMethods.missing, {
  deterministic: false,
  useContext: true
})
Object.assign(defaultMethods.missing_some, {
  deterministic: false,
  useContext: true
})
// @ts-ignore Allow custom attribute
defaultMethods['<'].compile = function (data, buildState) {
  if (Array.isArray(data)) {
    if (data.length === 2) {
      return (
        '(' +
        buildString(data[0], buildState) +
        ' < ' +
        buildString(data[1], buildState) +
        ')'
      )
    }
    if (data.length === 3) {
      const a = buildString(data[0], buildState)
      const b = buildString(data[1], buildState)
      const c = buildString(data[2], buildState)
      return `${a} < ${b} && ${b} < ${c}`
    }
  }
  return false
}
// @ts-ignore Allow custom attribute
defaultMethods['<='].compile = function (data, buildState) {
  if (Array.isArray(data)) {
    if (data.length === 2) {
      return (
        '(' +
        buildString(data[0], buildState) +
        ' <= ' +
        buildString(data[1], buildState) +
        ')'
      )
    }
    if (data.length === 3) {
      const a = buildString(data[0], buildState)
      const b = buildString(data[1], buildState)
      const c = buildString(data[2], buildState)
      return `${a} <= ${b} && ${b} <= ${c}`
    }
  }
  return false
}
// @ts-ignore Allow custom attribute
defaultMethods.min.compile = function (data, buildState) {
  if (Array.isArray(data)) {
    return `Math.min(${data
      .map((i) => buildString(i, buildState))
      .join(', ')})`
  }
  return false
}
// @ts-ignore Allow custom attribute
defaultMethods.max.compile = function (data, buildState) {
  if (Array.isArray(data)) {
    return `Math.max(${data
      .map((i) => buildString(i, buildState))
      .join(', ')})`
  }
  return false
}
// @ts-ignore Allow custom attribute
defaultMethods['>'].compile = function (data, buildState) {
  if (Array.isArray(data)) {
    if (data.length === 2) {
      return (
        '(' +
        buildString(data[0], buildState) +
        ' > ' +
        buildString(data[1], buildState) +
        ')'
      )
    }
  }
  return false
}
// @ts-ignore Allow custom attribute
defaultMethods['>='].compile = function (data, buildState) {
  if (Array.isArray(data)) {
    if (data.length === 2) {
      return (
        '(' +
        buildString(data[0], buildState) +
        ' >= ' +
        buildString(data[1], buildState) +
        ')'
      )
    }
  }
  return false
}
// @ts-ignore Allow custom attribute
defaultMethods['=='].compile = function (data, buildState) {
  if (Array.isArray(data)) {
    if (data.length === 2) {
      return (
        '(' +
        buildString(data[0], buildState) +
        ' == ' +
        buildString(data[1], buildState) +
        ')'
      )
    }
  }
  return false
}
// @ts-ignore Allow custom attribute
defaultMethods['!='].compile = function (data, buildState) {
  if (Array.isArray(data)) {
    if (data.length === 2) {
      return (
        '(' +
        buildString(data[0], buildState) +
        ' != ' +
        buildString(data[1], buildState) +
        ')'
      )
    }
  }
  return false
}
// @ts-ignore Allow custom attribute
defaultMethods.if.compile = function (data, buildState) {
  if (Array.isArray(data)) {
    if (data.length >= 3) {
      data = [...data]

      if (data.length % 2 !== 1) {
        data.push(null)
      }

      const onFalse = data.pop()

      let str = ''
      while (data.length) {
        const condition = data.shift()
        const onTrue = data.shift()
        str += `(${buildString(condition, buildState)}) ? ${buildString(onTrue, buildState)} : `
      }

      return '(' + str + `${buildString(onFalse, buildState)}` + ')'
    }
  }
  return false
}
// @ts-ignore Allow custom attribute
defaultMethods['!=='].compile = function (data, buildState) {
  if (Array.isArray(data)) {
    if (data.length === 2) {
      return (
        '(' +
        buildString(data[0], buildState) +
        ' !== ' +
        buildString(data[1], buildState) +
        ')'
      )
    }
  }
  return false
}
// @ts-ignore Allow custom attribute
defaultMethods['==='].compile = function (data, buildState) {
  if (Array.isArray(data)) {
    if (data.length === 2) {
      return (
        '(' +
        buildString(data[0], buildState) +
        ' === ' +
        buildString(data[1], buildState) +
        ')'
      )
    }
  }
  return false
}
// @ts-ignore Allow custom attribute
defaultMethods['+'].compile = function (data, buildState) {
  if (Array.isArray(data)) {
    return `(${data
      .map((i) => `(+${buildString(i, buildState)})`)
      .join(' + ')})`
  } else if (typeof data === 'string' || typeof data === 'number') {
    return `(+${buildString(data, buildState)})`
  } else {
    return `([].concat(${buildString(
      data,
      buildState
    )})).reduce((a,b) => (+a)+(+b), 0)`
  }
}

// @ts-ignore Allow custom attribute
defaultMethods['%'].compile = function (data, buildState) {
  if (Array.isArray(data)) {
    return `(${data
      .map((i) => `(+${buildString(i, buildState)})`)
      .join(' % ')})`
  } else {
    return `(${buildString(data, buildState)}).reduce((a,b) => (+a)%(+b))`
  }
}
// @ts-ignore Allow custom attribute
defaultMethods.or.compile = function (data, buildState) {
  if (Array.isArray(data)) {
    return `(${data.map((i) => buildString(i, buildState)).join(' || ')})`
  } else {
    return `(${buildString(data, buildState)}).reduce((a,b) => a||b, false)`
  }
}
// @ts-ignore Allow custom attribute
defaultMethods.in.compile = function (data, buildState) {
  if (Array.isArray(data)) {
    return `(${buildString(data[1], buildState)} || []).includes(${buildString(
      data[0],
      buildState
    )})`
  }
  return false
}
// @ts-ignore Allow custom attribute
defaultMethods.and.compile = function (data, buildState) {
  if (Array.isArray(data)) {
    return `(${data.map((i) => buildString(i, buildState)).join(' && ')})`
  } else {
    return `(${buildString(data, buildState)}).reduce((a,b) => a&&b, true)`
  }
}
// @ts-ignore Allow custom attribute
defaultMethods['-'].compile = function (data, buildState) {
  if (Array.isArray(data)) {
    return `${data.length === 1 ? '-' : ''}(${data
      .map((i) => `(+${buildString(i, buildState)})`)
      .join(' - ')})`
  }
  if (typeof data === 'string' || typeof data === 'number') {
    return `(-${buildString(data, buildState)})`
  } else {
    return `((a=>(a.length===1?a[0]=-a[0]:a)&0||a)([].concat(${buildString(
      data,
      buildState
    )}))).reduce((a,b) => (+a)-(+b))`
  }
}
// @ts-ignore Allow custom attribute
defaultMethods['/'].compile = function (data, buildState) {
  if (Array.isArray(data)) {
    return `(${data
      .map((i) => `(+${buildString(i, buildState)})`)
      .join(' / ')})`
  } else {
    return `(${buildString(data, buildState)}).reduce((a,b) => (+a)/(+b))`
  }
}
// @ts-ignore Allow custom attribute
defaultMethods['*'].compile = function (data, buildState) {
  if (Array.isArray(data)) {
    return `(${data
      .map((i) => `(+${buildString(i, buildState)})`)
      .join(' * ')})`
  } else {
    return `(${buildString(data, buildState)}).reduce((a,b) => (+a)*(+b))`
  }
}
// @ts-ignore Allow custom attribute
defaultMethods.cat.compile = function (data, buildState) {
  if (typeof data === 'string') {
    return JSON.stringify(data)
  } else if (Array.isArray(data)) {
    return `(${['', ...data]
      .map((i) => buildString(i, buildState))
      .join(' + ')})`
  }
  return false
}
// @ts-ignore Allow custom attribute
defaultMethods.not.compile = defaultMethods['!'].compile = function (
  data,
  buildState
) {
  return `(!(${buildString(data, buildState)}))`
}
// @ts-ignore Allow custom attribute
defaultMethods['!!'].compile = function (data, buildState) {
  return `(!!(${buildString(data, buildState)}))`
}
defaultMethods.none.deterministic = defaultMethods.some.deterministic
defaultMethods.get.compile = function (data, buildState) {
  let defaultValue = null
  let key = data
  let obj = null
  if (Array.isArray(data) && data.length <= 3) {
    obj = data[0]
    key = data[1]
    defaultValue = typeof data[2] === 'undefined' ? null : data[2]

    // Bail out if the key is dynamic; dynamic keys are not really optimized by this block.
    if (key && typeof key === 'object') return false

    key = key.toString()
    const pieces = splitPath(key)
    if (!chainingSupported) {
      return `(((a,b) => (typeof a === 'undefined' || a === null) ? b : a)(${pieces.reduce(
        (text, i) => {
          return `(${text}||0)[${JSON.stringify(i)}]`
        },
        `(${buildString(obj, buildState)}||0)`
      )}, ${buildString(defaultValue, buildState)}))`
    }
    return `((${buildString(obj, buildState)})${pieces
      .map((i) => `?.[${buildString(i, buildState)}]`)
      .join('')} ?? ${buildString(defaultValue, buildState)})`
  }
  return false
}
// @ts-ignore Allow custom attribute
defaultMethods.var.compile = function (data, buildState) {
  let key = data
  let defaultValue = null
  buildState.varTop = buildState.varTop || new Set()
  if (
    !key ||
    typeof data === 'string' ||
    typeof data === 'number' ||
    (Array.isArray(data) && data.length <= 2)
  ) {
    if (Array.isArray(data)) {
      key = data[0]
      defaultValue = typeof data[1] === 'undefined' ? null : data[1]
    }
    if (typeof key === 'undefined' || key === null || key === '') {
      // this counts the number of var accesses to determine if they're all just using this override.
      // this allows for a small optimization :)
      return 'state[Override]'
    }
    if (typeof key !== 'string' && typeof key !== 'number') {
      buildState.useContext = true
      return false
    }
    key = key.toString()
    if (key.includes('../')) {
      buildState.useContext = true
      return false
    }
    const pieces = splitPath(key)
    const [top] = pieces
    buildState.varTop.add(top)
    // support older versions of node
    if (!chainingSupported) {
      return `(((a,b) => (typeof a === 'undefined' || a === null) ? b : a)(${pieces.reduce(
        (text, i) => {
          return `(${text}||0)[${JSON.stringify(i)}]`
        },
        '(context||0)'
      )}, ${buildString(defaultValue, buildState)}))`
    }
    return `(context${pieces
      .map((i) => `?.[${JSON.stringify(i)}]`)
      .join('')} ?? ${buildString(defaultValue, buildState)})`
  }
  buildState.useContext = true
  return false
}

const defaultMethods$1 = {
  ...defaultMethods,
  ...YieldingIterators
}

// @ts-check
const omitUndefined = function omitUndefined (obj) {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === undefined) {
      delete obj[key]
    }
  })
  return obj
}

// @ts-check

/**
 * An engine capable of running synchronous JSON Logic.
 */
class LogicEngine {
  /**
   *
   * @param {Object} methods An object that stores key-value pairs between the names of the commands & the functions they execute.
   * @param {{ yieldSupported?: Boolean, disableInline?: Boolean, permissive?: boolean }} options
   */
  constructor (
    methods = defaultMethods$1,
    options = { yieldSupported: false, disableInline: false, permissive: false }
  ) {
    this.disableInline = options.disableInline
    this.methods = { ...methods }
    /** @type {{yieldSupported?: Boolean, disableInline?: Boolean, permissive?: boolean}} */
    this.options = { ...options }
  }

  /**
   * An internal method used to parse through the JSON Logic at a lower level.
   * @param {*} logic The logic being executed.
   * @param {*} context The context of the logic being run (input to the function.)
   * @param {*} above The context above (can be used for handlebars-style data traversal.)
   * @returns {{ result: *, func: string }}
   */
  _parse (logic, context, above) {
    const [func] = Object.keys(logic)
    const data = logic[func]
    if (this.methods[func]) {
      if (typeof this.methods[func] === 'function') {
        const input = this.run(data, context, { above })
        if (this.options.yieldSupported && checkYield(input)) return { result: input, func }
        return { result: this.methods[func](input, context, above, this), func }
      }
      if (typeof this.methods[func] === 'object') {
        const { method, traverse } = this.methods[func]
        const shouldTraverse = typeof traverse === 'undefined' ? true : traverse
        const parsedData = shouldTraverse
          ? this.run(data, context, { above })
          : data
        if (this.options.yieldSupported && checkYield(parsedData)) return { result: parsedData, func }
        return { result: method(parsedData, context, above, this), func }
      }
    }
    if (this.options.permissive) return { result: logic, func }
    throw new Error(`Method '${func}' was not found in the Logic Engine.`)
  }

  /**
   *
   * @param {String} name The name of the method being added.
   * @param {Function|{ traverse?: Boolean, method: Function, deterministic?: Function | Boolean }} method
   * @param {{ deterministic?: Boolean, yields?: Boolean, useContext?: Boolean }} annotations This is used by the compiler to help determine if it can optimize the function being generated.
   */
  addMethod (name, method, { deterministic, yields, useContext } = {}) {
    if (typeof method === 'function') {
      method = { method, traverse: true }
    } else {
      method = { ...method }
    }

    Object.assign(method, omitUndefined({ yields, useContext, deterministic }))
    this.methods[name] = declareSync(method)
  }

  /**
   * Adds a batch of functions to the engine
   * @param {String} name
   * @param {Object} obj
   * @param {{ deterministic?: Boolean, yields?: Boolean, useContext?: Boolean, async?: Boolean, sync?: Boolean }} annotations Not recommended unless you're sure every function from the module will match these annotations.
   */
  addModule (name, obj, annotations) {
    Object.getOwnPropertyNames(obj).forEach((key) => {
      if (typeof obj[key] === 'function' || typeof obj[key] === 'object') {
        this.addMethod(
          `${name}${name ? '.' : ''}${key}`,
          obj[key],
          annotations
        )
      }
    })
  }

  /**
   *
   * @param {*} logic The logic to be executed
   * @param {*} data The data being passed in to the logic to be executed against.
   * @param {{ above?: any }} options Options for the invocation
   * @returns {*}
   */
  run (logic, data = {}, options = {}) {
    const { above = [] } = options
    if (Array.isArray(logic)) {
      const result = logic.map((i) => this.run(i, data, { above }))
      if (this.options.yieldSupported && checkYield(result)) {
        return new EngineObject({
          result
        })
      }
      return result
    }
    if (logic && typeof logic === 'object' && Object.keys(logic).length > 0) {
      const { func, result } = this._parse(logic, data, above)
      if (this.options.yieldSupported && checkYield(result)) {
        if (result instanceof YieldStructure) {
          if (result._input) {
            result._logic = { [func]: result._input }
          }
          if (!result._logic) {
            result._logic = logic
          }
          return result
        }
        return new EngineObject({
          result: { [func]: result.data.result }
        })
      }
      return result
    }
    return logic
  }

  /**
   *
   * @param {*} logic The logic to be built.
   * @param {{ top?: Boolean, above?: any }} options
   * @returns {Function}
   */
  build (logic, options = {}) {
    const { above = [], top = true } = options
    if (top) {
      const constructedFunction = build(logic, {
        state: {},
        engine: this,
        above
      })
      if (typeof constructedFunction === 'function' || top === true) {
        return (...args) => {
          return typeof constructedFunction === 'function'
            ? constructedFunction(...args)
            : constructedFunction
        }
      }
      return constructedFunction
    }
    return logic
  }
}

// @ts-check

function timeout (n) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, n)
  })
}

function asyncPool ({
  free = [],
  max = 10,
  created = free.length,
  create = () => {}
} = {}) {
  const pool = async function () {
    if (free.length) {
      const func = free.pop()
      const promise = func(...arguments)
      // return the stateful function
      promise
        .then(() => {
          free.push(func)
        })
        .catch(() => {
          free.push(func)
        })
      return promise
    } else {
      if (created < max) {
        created++
        free.push(await create())
      }
      while (!free.length) {
        await timeout(250)
      }
      return pool(...arguments)
    }
  }
  return pool
}

// @ts-check

/**
 * An engine capable of running asynchronous JSON Logic.
 */
class AsyncLogicEngine {
  /**
   *
   * @param {Object} methods An object that stores key-value pairs between the names of the commands & the functions they execute.
   * @param {{ yieldSupported?: Boolean, disableInline?: Boolean, permissive?: boolean }} options
   */
  constructor (
    methods = defaultMethods$1,
    options = { yieldSupported: false, disableInline: false, permissive: false }
  ) {
    this.methods = { ...methods }
    /** @type {{yieldSupported?: Boolean, disableInline?: Boolean, permissive?: boolean}} */
    this.options = { ...options }
    this.disableInline = options.disableInline
    this.async = true
    this.fallback = new LogicEngine(methods, options)
  }

  /**
   * An internal method used to parse through the JSON Logic at a lower level.
   * @param {*} logic The logic being executed.
   * @param {*} context The context of the logic being run (input to the function.)
   * @param {*} above The context above (can be used for handlebars-style data traversal.)
   * @returns {Promise<{ func: string, result: * }>}
   */
  async _parse (logic, context, above) {
    const [func] = Object.keys(logic)
    const data = logic[func]
    if (this.methods[func]) {
      if (typeof this.methods[func] === 'function') {
        const input = await this.run(data, context, { above })
        if (this.options.yieldSupported && (await checkYield(input))) {
          return { result: input, func }
        }
        const result = await this.methods[func](input, context, above, this)
        return { result: Array.isArray(result) ? Promise.all(result) : result, func }
      }

      if (typeof this.methods[func] === 'object') {
        const { asyncMethod, method, traverse } = this.methods[func]
        const shouldTraverse =
          typeof traverse === 'undefined' ? true : traverse
        const parsedData = shouldTraverse
          ? await this.run(data, context, { above })
          : data

        if (this.options.yieldSupported && (await checkYield(parsedData))) {
          return { result: parsedData, func }
        }

        const result = await (asyncMethod || method)(
          parsedData,
          context,
          above,
          this
        )
        return { result: Array.isArray(result) ? Promise.all(result) : result, func }
      }
    }
    if (this.options.permissive) return { result: logic, func }
    throw new Error(`Method '${func}' was not found in the Logic Engine.`)
  }

  /**
   *
   * @param {String} name The name of the method being added.
   * @param {Function|{ traverse?: Boolean, method?: Function, asyncMethod?: Function, deterministic?: Function | Boolean }} method
   * @param {{ deterministic?: Boolean, yields?: Boolean, useContext?: Boolean, async?: Boolean, sync?: Boolean }} annotations This is used by the compiler to help determine if it can optimize the function being generated.
   */
  addMethod (
    name,
    method,
    { deterministic, async, sync, yields, useContext } = {}
  ) {
    if (typeof async === 'undefined' && typeof sync === 'undefined') sync = false
    if (typeof sync !== 'undefined') async = !sync
    if (typeof async !== 'undefined') sync = !async

    if (typeof method === 'function') {
      if (async) {
        method = { asyncMethod: method, traverse: true }
      } else {
        method = { method, traverse: true }
      }
    } else {
      method = { ...method }
    }

    Object.assign(method, omitUndefined({ yields, deterministic, useContext }))
    // @ts-ignore
    this.fallback.addMethod(name, method, { deterministic, yields, useContext })
    this.methods[name] = declareSync(method, sync)
  }

  /**
   * Adds a batch of functions to the engine
   * @param {String} name
   * @param {Object} obj
   * @param {{ deterministic?: Boolean, yields?: Boolean, useContext?: Boolean, async?: Boolean, sync?: Boolean }} annotations Not recommended unless you're sure every function from the module will match these annotations.
   */
  addModule (name, obj, annotations = {}) {
    Object.getOwnPropertyNames(obj).forEach((key) => {
      if (typeof obj[key] === 'function' || typeof obj[key] === 'object') {
        this.addMethod(
          `${name}${name ? '.' : ''}${key}`,
          obj[key],
          annotations
        )
      }
    })
  }

  /**
   *
   * @param {*} logic The logic to be executed
   * @param {*} data The data being passed in to the logic to be executed against.
   * @param {{ above?: any }} options Options for the invocation
   * @returns {Promise}
   */
  async run (logic, data = {}, options = {}) {
    const { above = [] } = options
    if (Array.isArray(logic)) {
      const result = await Promise.all(
        logic.map((i) => this.run(i, data, { above }))
      )

      if (this.options.yieldSupported && (await checkYield(result))) {
        return new EngineObject({
          result
        })
      }

      return result
    }

    if (logic && typeof logic === 'object' && Object.keys(logic).length > 0) {
      const { func, result } = await this._parse(logic, data, above)

      if (this.options.yieldSupported && (await checkYield(result))) {
        if (result instanceof YieldStructure) {
          if (result._input) {
            result._logic = { [func]: result._input }
          }
          if (!result._logic) {
            result._logic = logic
          }
          return result
        }

        return new EngineObject({
          result: { [func]: result.data.result }
        })
      }

      return result
    }

    return logic
  }

  /**
   *
   * @param {*} logic The logic to be built.
   * @param {{ top?: Boolean, above?: any, max?: Number }} options
   * @returns {Promise<Function>}
   */
  async build (logic, options = {}) {
    const { above = [], max = 100, top = true } = options
    if (top) {
      const constructedFunction = await buildAsync(logic, {
        engine: this,
        above,
        async: true,
        state: {}
      })

      const result = declareSync((...args) => {
        if (top === true) {
          try {
            const result =
              typeof constructedFunction === 'function'
                ? constructedFunction(...args)
                : constructedFunction
            return Promise.resolve(result)
          } catch (err) {
            return Promise.reject(err)
          }
        }

        const result =
          typeof constructedFunction === 'function'
            ? constructedFunction(...args)
            : constructedFunction

        return result
      }, top !== true && isSync(constructedFunction))
      // we can avoid the async pool if the constructed function is synchronous since the data
      // can't be updated :)
      if (top === true && constructedFunction && !constructedFunction[Sync]) {
        // we use this async pool so that we can execute these in parallel without having
        // concerns about the data.
        return asyncPool({
          free: [result],
          max,
          create: () => this.build(logic, { ...options, above })
        })
      } else {
        return typeof constructedFunction === 'function' || top === true
          ? result
          : constructedFunction
      }
    }

    return logic
  }
}

/**
 * @param {string[]} keep
 * @param {{ [key:string]: any }} obj
 */
function pick (keep, obj) {
  return Object.keys(obj).reduce((acc, i) => {
    if (keep.includes(i)) acc[i] = obj[i]
    return acc
  }, {})
}

/**
 * Takes functions and makes it possible to use them in a locked-down json-logic document.
 * @param {{ [key: string]: (...args: any[]) => any }} functions Functions to import into the engine.
 * @param {string[]} keep Methods to keep from the original logic engine
 * @returns {(...args: any[]) => (...args: any[]) => any}
 */
function asLogicSync (functions, keep = ['var'], engine = new LogicEngine()) {
  engine.methods = pick(keep, engine.methods)
  engine.addMethod('list', i => [].concat(i))
  Object.keys(functions).forEach(i => engine.addMethod(i, data => Array.isArray(data) ? functions[i](...data) : functions[i](data === null ? undefined : data)))
  return engine.build.bind(engine)
}

/**
 * Takes functions and makes it possible to use them in a locked-down json-logic document.
 * If performance becomes a problem, you may wish to optimize by creating a "new AsyncLogicEngine" yourself,
 * and adding the methods you're using as sync / async respectively. .addMethod(name, func, { sync: true })
 * This is meant to be a simple adapter.
 *
 * @param {{ [key: string]: (...args: any[]) => any }} functions
 * @param {string[]} keep
 * @returns {(...args: any[]) => Promise<(...args: any[]) => Promise<any>>}
 */
function asLogicAsync (functions, keep = ['var']) {
  return asLogicSync(functions, keep, new AsyncLogicEngine())
}

// @ts-check

const index = { LogicEngine, AsyncLogicEngine, Compiler, YieldStructure, EngineObject, Constants, defaultMethods: defaultMethods$1, asLogicSync, asLogicAsync, splitPath }

export { AsyncLogicEngine, Compiler, Constants, EngineObject, LogicEngine, YieldStructure, asLogicAsync, asLogicSync, index as default, defaultMethods$1 as defaultMethods, splitPath }
